<script>
import UserAvatarInitials from '@/components/general/UserAvatarInitials'
import InputField from '@/components/general/InputField'
export default {
  name: 'WorkspaceSelection',
  components: { UserAvatarInitials, InputField },
  computed: {
    userWorkspaces () {
      return this.$store.getters.getUserWorkspaces
    },
    filteredWorkspaces () {
      return this.search ? this.userWorkspaces.filter(workspace => this.search && workspace.name.toLowerCase().includes(this.search.toLowerCase())).filter(workspace => workspace.type === 'business') : this.userWorkspaces.filter(workspace => workspace.type === 'business')
    }
  },
  data () {
    return {
      ready: false,
      search: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const companies = vm.userWorkspaces.filter(w => w.type === 'business')
      if (vm.userWorkspaces.length === 1) {
        next({ name: 'individual.home.user' })
      } else if (vm.$store.getters.getThemeWorkspace && from.name === 'auth.login') {
        const workspaces = vm.userWorkspaces.filter(w => w.subdomain === vm.$store.getters.getThemeWorkspace)
        next({ name: workspaces[0].type + '.home.user', params: { companyDomain: workspaces[0].subdomain } })
      } else if (companies.length === 1) {
        next({ name: 'business.home.user', params: { companyDomain: companies[0].subdomain } })
      } else {
        vm.ready = true
        next()
      }
    })
  }
}
</script>
<template>
  <section class="workspace-selection--container" v-if="ready">
    <div class="workspace-selection--header">
      <img src="/assets/images/primary-logo.svg" />
    </div>
    <h2 class="workspace-selection--title">{{ $t('workspace.selection:select.workspace') }}</h2>
    <div class="workspace-selection--search-wrapper">
      <input-field background-color="#fff" small v-model="search" clearable :label="$t('global:search')" outlined></input-field>
    </div>
    <ul class="workspace-selection--items">
      <li v-for="(workspace, index) in filteredWorkspaces" :key="index">
        <router-link :to="{ name: workspace.type + '.home.user', params: { companyDomain: workspace.subdomain, workspace } }" tag="a" :key="workspace.name + index">
          <v-icon color="#1200d3">mdi-check-circle</v-icon>
          <v-icon color="#e8e8e8">mdi-checkbox-blank-circle-outline</v-icon>
          <v-avatar size="72">
            <v-img v-if="!!workspace.avatar && !(workspace.avatar.includes('/empty'))" :src="workspace.avatar" height="72px" width="72px"></v-img>
            <user-avatar-initials v-else :user="workspace" :size="72"/>
          </v-avatar>
          {{ workspace.type === 'individual' ? $t('workspace.type:individual') : workspace.name }}
        </router-link>
      </li>
    </ul>
    <p class="mt-10" v-if="search && filteredWorkspaces.length === 0">{{ $t('workspace.selection:empty.search') }}</p>
  </section>
</template>
<style lang="scss">
.workspace-selection--container {
  background-color: #F8F8F8;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30vh;
  padding-top: 128px;
  position: relative;
  h2.workspace-selection--title {
    font-family: $lato;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #242321;
    margin-bottom: 80px;
    padding: 0 20px;
  }
  .workspace-selection--header {
    display: flex;
    width: 100vw;
    height: 64px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 0;
    left: 0;

    img {
      height: 24px;
    }
  }
  .workspace-selection--search-wrapper {
    width: 450px;
    max-width: calc(100% - 40px);
  }
  .workspace-selection--items {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    padding: 0;
    justify-content: center;
    li {
      margin: 8px;
      display: flex;
      justify-content: center;
      flex: 1 0 21%; /* explanation below */
      margin-right: auto;
      a {
        width :236px;
        height: 180px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #212121;
        font-size: 16px;
        flex-direction: column;
        text-decoration: none;
        position: relative;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.06);

        &:hover {
          border: 1px solid rgb(18, 0, 211);

          .v-icon {
            &.mdi-checkbox-blank-circle-outline {
              display: none;
            }
            &.mdi-check-circle {
              display: block;
            }
          }
        }

        .v-icon {
          position: absolute;
          top: 8px;
          right: 8px;
          &.mdi-check-circle {
            display: none;
          }
        }
      }

      .v-avatar {
        margin-bottom: 16px;
        margin-top: 16px;
      }
    }
  }
  @media (max-width: 900px) {
    .workspace-selection--items { grid-template-columns: repeat(3, 1fr); }
  }
  @media (max-width: 768px) {
    .workspace-selection--items { grid-template-columns: repeat(2, 1fr); }
  }
  @media (max-width: 500px) {
    .workspace-selection--items { grid-template-columns: repeat(1, 1fr); }
  }
}
</style>
